/* frontend/src/styles/AdminInterface.css */

.CollapsibleContent {
  overflow: hidden;
}

.CollapsibleContent[data-state='open'] {
  animation: slideDown 150ms ease-out;
}

.CollapsibleContent[data-state='closed'] {
  animation: slideUp 150ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: spin 1s linear infinite;
}

.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--gray-4);
  border-radius: 99999px;
  width: 100%;
  height: 10px;
}

.ProgressIndicator {
  background-color: var(--accent-9);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}