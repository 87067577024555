/* frontend/src/styles/LandingPage.css */
.feature-icon {
    background-color: var(--accent-2);
    border-radius: 50%;
    padding: 12px;
    display: inline-flex;
    color: var(--accent-9);
  }
  
  .hero-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: var(--radius-3);
  }
  
  @media (max-width: 768px) {
    .hero-image {
      max-height: 200px;
    }
  }